const BtnNew = ({ handleClick, children, style, visible, className, Icon, colorIcon }) => {
  if (!visible) return null;

  return (
    <button
      style={{ ...style }}
      onClick={handleClick}
      className="flex h-[30px] items-center justify-start gap-2 rounded-[10px] bg-brand-400 p-2 px-5 text-white"
    >
      {Icon && <Icon className={colorIcon} />}
      {children}
    </button>
  );
};

export default BtnNew;
