import ButtonGeneral from "components/button-general";
import ModalGeneral from "components/modal";
import React, { useState } from "react";
import toast from "react-hot-toast";

export const Observaciones = ({
  close,
  token,
  defaultText,
  title,
  isDevoluciones,
  document,
  rol,
}) => {
  const [formData, setFormData] = useState(defaultText);
  const { REACT_APP_URL_BASE } = process.env;

  const Save = async () => {
    if (document && formData.length > 0) {
      httpApi(formData);
    }
  };

  const httpApi = async (textComment) => {
    const { id } = document;
    const options = {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(textComment),
    };
    const endpoint = `${REACT_APP_URL_BASE}/solicitudes/${id}/comments`;
    const response = await fetch(endpoint, options)
      .then((res) => res.json())
      .catch((error) => {
        return null;
      });
    if (response) {
      close();
      toast.success("Se ha creado exitosamente el comentario.", {
        duration: 3000,
        position: "top-right",
      });
    } else {
      toast.error("Ha ocurrido un error", {
        duration: 3000,
        position: "top-right",
      });
    }
  };

  return (
    <ModalGeneral title={title} close={close}>
      <div className="flex h-[450px] w-full flex-col overflow-y-scroll px-2">
        <textarea
          className="border-black	border-[2px] border-solid p-4 text-[18px]"
          rows={20}
          value={formData}
          onChange={(event) => {
            setFormData(event.target.value);
          }}
        ></textarea>
      </div>
      <div className="flex justify-end p-2 pt-2">
        <ButtonGeneral
          visible={[1, 4, 6, 7].includes(rol)}
          handleClick={Save}
          className={"w-[250px]"}
        >
          Guardar comentario
        </ButtonGeneral>

        {defaultText && isDevoluciones && (
          <ButtonGeneral
            visible={[1, 4, 6, 7].includes(rol)}
            handleClick={() => httpApi("")}
            className={"w-[250px]"}
          >
            Validar documento
          </ButtonGeneral>
        )}
      </div>
    </ModalGeneral>
  );
};
