import { formatDate, NumberMxn } from "helpers";
import TextInfo from "../text-info";
import {
  FaRegCheckCircle,
  FaCloudDownloadAlt,
  FaEye,
  FaPeopleCarry,
  FaCopy,
} from "react-icons/fa";
import { IoIosSend } from "react-icons/io";
import BtnNew from "components/btn-new";
import toast from "react-hot-toast";

const bgs = {
  proceso: "bg-yellow-100",
  autorizada: "bg-green-100",
  cancelada: "bg-red-100",
  solicitada: "bg-gray-100",
};

export default function ListSolicitudes({
  item,
  rol,
  loader,
  status,
  handleView,
  handleSendConfirmEmail,
  handleDownload,
  autorizar,
  CancelarSolicitud,
  setModalAcuse,
  setidGeneralSeleccionado,
  setModalAsignar,
  handleSendStp,
}) {
  const { general, oferta, asesor, mesa } = item;

  const renderButtons = () => {
    return (
      <>
        {status !== "reasignacion" && (
          <BtnNew
            handleClick={() => {
              handleView(item);
            }}
            className="flex h-[30px] items-center justify-center rounded-[10px] bg-brand-400 p-2 text-white"
            visible={true}
            Icon={FaRegCheckCircle}
            colorIcon={"text-green-300"}
          >
            Validar Datos
          </BtnNew>
        )}

        <BtnNew
          handleClick={() => handleDownload({ id: general.id })}
          visible={[1, 4, 8].includes(rol)}
          Icon={FaCloudDownloadAlt}
        >
          Descargar paq cred.
        </BtnNew>
        {general.IdStp || general.codeRastreo ? (
          <BtnNew
            handleClick={() => {
              if (general.codeRastreo) {
                setModalAcuse(true);
                setidGeneralSeleccionado(general.id);
              } else {
                toast.error("Esta solicitud no generó la clave de rastreo", {
                  position: "top-center",
                });
              }
            }}
            visible={[1, 4, 6].includes(rol)}
            Icon={FaEye}
          >
            Ver acuse cep
          </BtnNew>
        ) : general.estatus && "proceso" (
          <BtnNew
            handleClick={() => {
              handleSendStp && handleSendStp(general.id);
            }}
            visible={[1, 9].includes(rol)}
            Icon={IoIosSend}
          >
            Enviar disperción
          </BtnNew>
        )}

        {!(general.estatus === "autorizada") && (
          <BtnNew
            handleClick={() => {
              setModalAsignar(true);
              setidGeneralSeleccionado(general.id);
            }}
            visible={[1, 4, 6].includes(rol)}
            Icon={FaPeopleCarry}
          >
            Asignar asesor
          </BtnNew>
        )}

        {autorizar({
          status: general.estatus,
          isShow: general.isValidMail,
          id: general.id,
          acuse: general.isAcuseCep,
        })}
        {CancelarSolicitud({
          status: general.estatus,
          id: general.id,
        })}
        {!general.isValidMail ? (
          <BtnNew
            handleClick={() => {
              handleSendConfirmEmail(general.id);
            }}
            visible={[1, 4, 6, 7].includes(rol)}
            Icon={IoIosSend}
          >
            Reenviar conf. correo
          </BtnNew>
        ) : null}
      </>
    );
  };

  return (
    <div className="grid w-full grid-cols-[auto,270px] gap-y-5 gap-x-10  bg-gray-100">
      <div className="flex flex-col gap-y-2  rounded-md bg-[#fff] px-5 py-5">
        <div className="flex flex-row gap-x-10">
          <TextInfo label="Id:" value={general.id} />
          <TextInfo
            label="ESTATUS:"
            value={general.estatus}
            classContainer={bgs[general.estatus]}
          />
          <TextInfo label="Fecha y Hr:" value={formatDate(general.createdAt)} />
          <TextInfo label="Zell:" value={general.zellId} />
          <TextInfo label="Devoluciones:" value={general.countDevueltos} />

          <span className="flex flex-col px-2">
            <span className="text-[12px] uppercase">Id Veridas:</span>
            <button
              onClick={() => {
                navigator.clipboard.writeText(general?.validationVeridas);
              }}
            >
              <FaCopy className="text-brand-300" />
            </button>
          </span>

          <TextInfo
            label="Correo:"
            value={general.isValidMail ? "Validado" : "Pendiente de validación"}
          />
          <TextInfo label="Reserva:" value={general.reserva} />
          {general.manualCapture && (
            <TextInfo
              label="Captura"
              value={"MANUAL [FIMPE-RENAPO]"}
              classContainer={"bg-red-400"}
            />
          )}
        </div>
        <div className="border-2 border-dashed"></div>

        <div className="flex flex-row gap-x-10">
          <TextInfo
            label="Nombre del cliente:"
            value={`${general.nombre} ${general.secondName} ${general.apellidoP} ${general.apellidoM}`}
          />
          <TextInfo label="Nombre del Analista:" value={mesa?.fullName} />
          <TextInfo label="Nombre del Asesor:" value={asesor?.fullName} />
        </div>
        <div className="border-2 border-dashed"></div>
        <div className="flex w-[100%] flex-row gap-4 rounded-[10px] bg-brand-100 p-2">
          <TextInfo label="Tipo:" value={oferta.tipo} />
          <TextInfo
            label="Monto del crédito:"
            value={NumberMxn(oferta?.total ?? 0)}
          />
          <TextInfo label="No de quicenas:" value={oferta?.plazo} />
          <TextInfo
            label="Pago quincenal:"
            value={NumberMxn(oferta?.pago ?? 0)}
          />
          <TextInfo
            label="Solvencia:"
            value={NumberMxn(oferta?.capacidad ?? 0)}
          />
          <TextInfo
            label="Monto a dispersar:"
            value={NumberMxn(oferta?.dispocicion ?? 0)}
          />
          <TextInfo
            label=" Pago total:"
            value={NumberMxn(oferta?.pagoTotal ?? 0)}
          />
        </div>
      </div>
      <div className="flex flex-col justify-center gap-2 rounded-md bg-[#fff] px-5 py-5">
        {loader ? "Procesando" : renderButtons()}
      </div>
    </div>
  );
}
